import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AboutSuccessPage = () => (
  <Layout>
    <SEO title="Thanks" />
    <h2>Ευχαριστούμε!</h2>
    <p>Το μύνημα σας εστάλη. Αν χρειαστεί θα επικοινωνήσουμε μαζί σας. Έως τότε, οδηγάτε και παρκάρετε σωστά!</p>
  </Layout>
)

export default AboutSuccessPage
